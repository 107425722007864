(function($){
    fn_part_ui_timeline($);
})(jQuery);

function fn_part_ui_timeline($) {
    const $fn = $(".part_ui_timeline");

    if ($fn.length) {
        $fn.each(function(i){
            let $el = $(this);
            let col_length = $el.find(".wrp_timeline_inner .col").length,
                col_delay = 10 / col_length;

            $el.attr("data-reveal-timeline",i);

            function fn_width() {
                let col_length = $el.find(".wrp_timeline_inner .col").length,
                    col_width = $el.find(".wrp_timeline_inner .col").outerWidth(),
                    col = 100 - (100 / col_length);
                if ($el.filter("[data-timeline-slider]").length) {
                    $el.find(".wrp_timeline_line").css("width",col_width*(col_length-1)+"px");
                } else {
                    $el.find(".wrp_timeline_line, .wrp_timeline_bottom").css("width",col+"%");
                }
            }
            fn_width();
            $window.on("resize",fn_width);


            let percents = [];

            if (!$("html.ie").length && !$("html.no-sr").length) {
                if (sr.isSupported()) {
                    sr.reveal(`[data-reveal-timeline="${i}"]`, {
                        duration: 1000,
                        easing: 'ease',
                        scale: null,
                        distance: 0,
                        delay: 300,
                        viewFactor: 0.5,
                        reset: false,
                        mobile: true,
                        container: document.getElementsByTagName("section"),
                        beforeReveal: function (domEl) {
                            $(domEl).attr("data-reveal-timeline", "revealed");
                            $el.find(".wrp_timeline_inner .col").each(function(index){
                                percents.push((100 / (col_length-1)) * index);
                                let delay = col_delay * index;
                                $(this).find(".elm_item_dot").css("animation-delay",`${delay}s`);
                            });
                            $el.append(`
                                <style>
                                    @keyframes timeline_${i} {
                                    ${percents.map(percent =>`
                                      ${percent}% {
                                        width: ${percent}%;
                                        }
                                    `).join('')}
                                    }
                                </style>
                            `);
                            $el.find(".wrp_timeline_line span").css("animation",`10s ease 0s forwards 1 timeline_${i}`);
                        },
                        afterReveal: function (domEl) {
                            $(domEl).removeAttr("style");
                        }
                    }, 0);
                }
            }
        });
    }
}