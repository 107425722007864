Element.prototype._addDataValue = function(key, value) {
    key = `data-${key}`

    let attribute = this.getAttribute(key)

    if (attribute === null) {
        this.setAttribute(key, value)
    } else {
        value = value.split(' ')
        attribute = attribute.split(' ')

        value.map(value => !attribute.includes(value) && attribute.push(value))

        this.setAttribute(key, attribute.join(' '))
    }
}

Element.prototype._removeDataValue = function(key, value) {
    const result = []

    key = `data-${key}`

    const attribute = this.getAttribute(key)

    if (attribute === null) return

    attribute.split(' ').map(attribute => !value.split(' ').includes(attribute) && result.push(attribute))

    if (result.length !== 0) {
        this.setAttribute(key, result.join(' '))
    } else {
        this.removeAttribute(key)
    }
}

Element.prototype._hasDataValue = function(key, value) {
    key = `data-${key}`

    const attribute = this.getAttribute(key)

    if (attribute === null) return false
    return attribute.split(' ').includes(value)
}

const LibCookieConsent = {
    init: () => {
        const type = localStorage.getItem('lib-cookieconsent')

        if (type !== null) {
            JSON.parse(type).forEach(type => LibCookieConsent.append(type))
        }
    },
    set: (type) => {
        localStorage.setItem('lib-cookieconsent', JSON.stringify(type))
        localStorage.setItem('lib-cookieconsent-expire', (Date.now() + 31556926 * 1000).toString())

        if (type.length > 0) {
            type.forEach(type => LibCookieConsent.append(type))
        } else {
            localStorage.setItem('lib-cookieconsent', JSON.stringify([]))
            LibCookieConsent.remove()
        }
    },
    remove: () => {
        document.cookie.split(';').forEach(c => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        })
    },
    append: (type) => {
        document.querySelectorAll('[data-lib-cookieconsent]').forEach(elm => {
            if (type === 'all' || elm.getAttribute('data-lib-cookieconsent') === type) {
                const script = document.createElement('script')
                let delay = 0

                ;[...elm.attributes].forEach((attribute) => {
                    if (attribute.specified) {
                        if (attribute.name.indexOf('data-lib-cookieconsent') === -1 && attribute.name.indexOf('type') === -1) {
                            script.setAttribute(attribute.name, attribute.value)
                        }
                    }
                })

                script.innerHTML = elm.innerHTML

                if (elm.getAttribute('data-lib-cookieconsent-delay')) {
                    delay = parseInt(elm.getAttribute('data-lib-cookieconsent-delay'))
                }

                setTimeout(() => {
                    if (elm.closest('body') !== null) {
                        document.body.appendChild(script)
                    } else if (elm.closest('head') !== null) {
                        document.head.appendChild(script)
                    }

                    elm.remove()
                }, delay)
            }
        })
    }
}

const ComponentCookieConsent = (selector) => {
    if (document.querySelector('.part_form_cookieconsent') !== null || selector === null) {
        return
    }

    if (localStorage.getItem('lib-cookieconsent') === null || parseInt(localStorage.getItem('lib-cookieconsent-expire')) < Date.now()) {
        setTimeout(() => {
            document.documentElement.classList.add(`overflow-hidden`)
            selector._addDataValue('state', 'active')
            selector.classList.add('is-animate')
        }, 1500)
    }

    selector.addEventListener('click', () => {
        selector.classList.add('is-mobile-show')
    })

    selector.querySelector('[data-lib-cookieconsent-approve]').addEventListener('click', () => {
        LibCookieConsent.set(['performance', 'marketing'])
        selector.classList.remove('is-animate')

        setTimeout(() => {
            selector._removeDataValue('state', 'active')
            selector.remove()
            document.documentElement.classList.remove(`overflow-hidden`)
        }, 500)
    })
}

const ComponentCookieConsentForm = (selector) => {
    const type = localStorage.getItem('lib-cookieconsent')

    if (selector === null) {
        return
    }

    if (type !== null) {
        selector.querySelectorAll('input:not([disabled])').forEach(input => input.checked = false)

        JSON.parse(type).forEach(type => {
            if (selector.querySelector(`input[value="${type}"]`) !== null) {
                selector.querySelector(`input[value="${type}"]`).checked = true
            }
        })
    }

    selector.addEventListener('submit', e => {
        e.preventDefault()

        let type = []

        selector.querySelectorAll('input:not([disabled])').forEach(input => {
            input.checked && type.push(input.value)
        })

        LibCookieConsent.set(type)
        location.reload()
    })
}

LibCookieConsent.init()
ComponentCookieConsent(document.querySelector('.part_cookieconsent'))
ComponentCookieConsentForm(document.querySelector('.part_form_cookieconsent'))

window.addEventListener('pageshow',(event) => {
    if (event.persisted) {
        window.location.reload();
    }
})
