(function($){
    const $fn = $(".comp_contact");
    if ($fn.length) {
        const $map = $fn.find("[data-map]");
        $.getScript(cdnjs.googlemap).done(function () {
            $map.nl_google_map('init');
            $map.nl_google_map('markers_add');

            $fn.on("click","[data-marker-gps]",function() {
               let $elm = $(this),
                   $position = $elm.data('marker-gps');

                $elm.addClass("mod--active").closest(".elm_body_content").find("[data-marker-gps]").not($elm).removeClass("mod--active");
                $map.nl_google_map('position',$position);
            });
        });


        $(document).on('click','[data-switch-iframe]',function(e) {

            var mapKey = $(this).attr('data-switch-iframe');

            $('[data-iframe-map]').hide();
            $('[data-iframe-map="' +mapKey  + '"]').show();

        });
    }
})(jQuery);