(function($){
    const $fn = $(".comp_locations");

    if ($fn.length) {
        let $data_info = $fn.find("[data-info-json]").data("info-json"),
            $location_svg = $fn.find("[data-location-svg]");

        $fn.on("click","[data-nav-id]",function(){
            let $el = $(this),
                $nav_id = $el.data("nav-id"),
                $location_info = $data_info[$nav_id+1];

            $el.addClass("mod--active").closest(".elm_body_nav").find("[data-nav-id]").not(this).removeClass("mod--active");
            $location_svg.attr("data-location-svg",$nav_id).find("[data-location-id]").removeClass("mod--active").filter('[data-location-id="'+$nav_id+'"]').addClass("mod--active");

            $fn.find("[data-info-headline]").text($location_info.headline);
            $fn.find("[data-info-count]").text($location_info.count).prop('counter',0).stop(true).animate({
                counter: $location_info.count
            }, {
                duration: 1000,
                easing: 'swing',
                step: function (now) {
                    $fn.find("[data-info-count]").text(Math.ceil(now));
                }
            });

            $fn.find("[data-info-locations]").html("");
            if ($location_info.locations.length > 3) {
                $fn.find("[data-info-locations]").addClass("mod--size-small");
            } else {
                $fn.find("[data-info-locations]").removeClass("mod--size-small");
            }

            $location_info.locations.forEach(function(item){
                $fn.find("[data-info-locations]").append(`
                    <div class="elm_item">
                        <h4 class="part_ui_headline mod--small"><span>${item.headline}</span><span class="icon icon--marker"></span></h4>
                        <div class="part_ui_wsw">
                            ${item.wsw}
                        </div>
                    </div>
                `);
            });
        });

        $location_svg.on("click","[data-location-id] [data-text]", function() {
            let $el = $(this),
                $nav_id = $el.closest("[data-location-id]").data("location-id");

            $fn.find("[data-nav-id]").filter('[data-nav-id="'+$nav_id+'"]').trigger("click")
        });

        $fn.on("click", "[data-toggle]", function() {
            $fn.find("[data-toggle]").removeClass("state--active");
            $(this).addClass("state--active");
        });
    }
})(jQuery);