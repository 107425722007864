(function($){
    fn_comp_boxes($)
})(jQuery);

function fn_comp_boxes($) {
    const $fn = $(".comp_boxes");
    $fn.find('.mod--lib-tilt').tilt({
        scale: 1.035
    });

    if ($fn.length) {
        if (typeof $html[0].style.grid !== 'string') {
            $html.addClass("no-grid");
            $.getScript(cdnjs.masonry).done(function () {
                cssLoaded(function() {
                    $fn.find(".elm_body_content").masonry({
                        itemSelector: '.col',
                        columnWidth: $fn.find(".elm_body_content").data("masonry-col"),
                        percentPosition: true
                    });
                })
            });
        }
    }
}