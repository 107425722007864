(function($){
    fn_comp_site_adv_timeline($);
})(jQuery);

function fn_comp_site_adv_timeline($) {
    const $fn = $(".comp_site_adv_timeline");

    if ($fn.length) {
        $.getScript(cdnjs.dragscroll);

        $fn.each(function() {
            let $elm = $(this),
                $timeline = $elm.closest($fn).find(".part_ui_timeline .wrp_part"),
                $timeline_col = $timeline.find(".wrp_timeline_inner .col"),
                $timeline_col_width = $timeline_col.outerWidth(),
                $timeline_end = $timeline_col.length*$timeline_col_width,
                $timeline_nav = $elm.find(".elm_nav .col--buttons");

            if ($elm.closest($fn).find(".part_ui_timeline").filter('[data-timeline-slider="desktop"]').length) {
                $timeline.addClass("dragscroll");
            }

            fn_nav_visibility();
            $timeline.on("scroll",fn_nav_scroll);
            $window.on("resize",fn_nav_visibility);

            $elm.stop().on("click", "[data-timeline-nav]", function(){
                let $elm = $(this);

                if ($elm.data("timeline-nav") === "next") {
                    $timeline.animate( { scrollLeft: '+='+ $timeline_col_width}, 300);
                } else {
                    $timeline.animate( { scrollLeft: '-='+ $timeline_col_width}, 300);
                }
            });

            function fn_nav_visibility() {
                let $timeline_col = $timeline.find(".wrp_timeline_inner .col"),
                    $timeline_col_width = $timeline_col.outerWidth();

                if ($timeline.width()+1 < $timeline_col.length * $timeline_col_width) {
                    $timeline_nav.show();
                } else {
                    $timeline_nav.hide();
                }
            }

            function fn_nav_scroll() {
                if ($timeline.scrollLeft()+$timeline.width() > $timeline_end-$timeline_col_width/2) {
                    $timeline.parent().removeClass("mod--state-start").addClass("mod--state-end");
                }
                if ($timeline.scrollLeft() === 0) {
                    $timeline.parent().removeClass("mod--state-end").addClass("mod--state-start");
                }
            }
        });
    }
}