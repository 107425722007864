(function($) {
    const fn = $(".comp_clients");

    if (fn.length) {
        console.log('clients page');
        fn.on("click", "[data-toggle-group]", function() {
            let elm = $(this);

            if (elm.attr("aria-expanded") === "false") {
                elm.attr("aria-expanded",true).closest(".elm_body_item").addClass("mod--active");
                elm.next(".wrp_item_content").slideDown(300).removeAttr("aria-hidden");
            } else {
                elm.attr("aria-expanded",false).closest(".elm_body_item").removeClass("mod--active");
                elm.next(".wrp_item_content").slideUp(300).attr("aria-hidden",true);
            }
        });
    }
})(jQuery);
