var fn_js_actions = {
    closeDialog: function() {
        nl_lib_dialog.close();
    },
    showTimeSlotAddFields: function() {
      if(typeof showHideTimeSlotsAddListFields !== 'undefined') {
          showHideTimeSlotsAddListFields();
      }
    },
    showHideTimeSlotsAddFields: function() {
      if(typeof showHideTimeSlotsAddFields !== 'undefined') {
          showHideTimeSlotsAddFields();
      }
    },
    initFixedTable: function() {
        if(typeof initFixedTable !== 'undefined') {
            initFixedTable();
        }
    },
    multipleCheckboxInit: function() {
        if(typeof multipleCheckboxManager !== 'undefined') {
            multipleCheckboxManager.init();
        }
    },
    process: function(payload) {

        if(typeof payload.payload === 'undefined' || payload.payload.jsActions === 'undefined') {
            return;
        }
        var _this = this;

        $.each(payload.payload.jsActions, function(index,action) {
            if(typeof _this[action] !== 'undefined') {
                return _this[action]();
            }
        });
    }
};
