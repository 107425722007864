let antispam = function () {
    if ($('#snippet-contactform').length) {
        setTimeout(function () {
            $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
        }, 3000);
    }
};

let antispaminquiry = function () {
    if ($('#snippet-inquiryform').length) {
        setTimeout(function () {
            $('#snippet-inquiryform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
        }, 3000);
    }
};