$.fn.nl_google_map = function(event,data) {
    return this.each(function() {
        var $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }
        else if (event === 'markers_add') {
            nl_google_map_markers_add_method(data);
        }

        else if (event === 'markers_remove') {
            nl_google_map_markers_remove_method();
        }

        else if (event === 'markers_reload') {
            nl_google_map_markers_remove_method();
            nl_google_map_markers_add_method();
        }

        else if (event === 'position') {
            nl_google_map_position_method(data);
        }

        else if (event === 'marker_click') {
            nl_google_map_marker_click_method(data);
        }

        function nl_google_map_init() {
            var $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers = $this.data("markers"),
                $scroll = false,
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    scrollwheel: false,
                    draggable: false,
                    mapTypeControl: false,
                    disableDefaultUI: false,
                    clicable: false,
                    styles: [
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#04234d"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#04234d"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#f2f5f7"
                                },
                                {
                                    "saturation": "0"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#c8dff3"
                                },
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#c8dff3"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#40618a"
                                },
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.place_of_worship",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#c8dff3"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.school",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#c8dff3"
                                },
                                {
                                    "saturation": "0"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.sports_complex",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#c8dff3"
                                },
                                {
                                    "saturation": "0"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#40618a"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#40618a"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#40618a"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#60a4dc"
                                },
                                {
                                    "visibility": "on"
                                },
                                {
                                    "saturation": "0"
                                }
                            ]
                        }
                    ],
                    fullscreenControl: false,
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM
                    },
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    }
                };

            var $map = new google.maps.Map($this[0], $map_options);

            if ($this.filter("[data-position-move]").length && $(window).width() > 768) {
                var center_moved = false;
                google.maps.event.addListener($map, 'bounds_changed', function() {
                    if(!center_moved){
                        var offsetX = 0.1,
                            span = $map.getBounds().toSpan(),
                            newCenter = {
                                lat: parseFloat($coords[0]),
                                lng: parseFloat($coords[1]) + span.lng() * offsetX
                            };

                        $map.panTo(newCenter);

                        center_moved = true;
                    }
                });
            }


            if ($this.is("[data-scroll]")) {
                $scroll = true;
            }

            google.maps.event.addListener($map, 'click', function () {
                $map.setOptions({
                    scrollwheel: $scroll, draggable: true
                });
            });

            $(document).on("click", function (e) {
                if ($(e.target).closest($this).length === 0) {
                    $map.setOptions({
                        scrollwheel: false, draggable: false
                    });
                }
            });

            function nl_google_map_position(data) {
                var $location = data.split(/[\s,]+/);

                if ($this.filter("[data-position-move]").length && $(window).width() > 768) {
                    var offsetX = 0.1,
                        span = $map.getBounds().toSpan(),
                        newCenter = {
                            lat: parseFloat($location[0]),
                            lng: parseFloat($location[1]) + span.lng() * offsetX
                        };
                    $map.panTo(newCenter);
                } else {
                    var latLng = new google.maps.LatLng($location[0],$location[1]);
                    $map.panTo(latLng);
                }

                $map.setZoom($zoom);
            }
            function nl_google_map_markers_add(callback) {
                var $bounds = new google.maps.LatLngBounds();
                var $infoWindow = new google.maps.InfoWindow({
                    content: "",
                    maxWidth: 190
                });

                if ($this.filter("[data-marker]").length) {
                    var $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));
                    var $main_marker_image = {
                        url: $this.data("marker"),
                        anchor: new google.maps.Point(30, 70),
                        scaledSize: new google.maps.Size(53, 80)
                    };

                    var $main_marker = new google.maps.Marker({
                        position: $main_marker_position,
                        map: $map,
                        icon: $main_marker_image,
                        zIndex: 9999
                    });

                    if ($this.data("marker-url").length > 0) {
                        google.maps.event.addListener($main_marker, 'click', function(){
                            window.location.href = $this.data("marker-url")
                        });
                    }

                    $markers_temp.push($main_marker);
                    $bounds.extend($main_marker_position);
                }
                if ($this.filter("[data-markers]").length) {
                    $markers = $this.data("markers");
                    for (var i = 0; i < $markers.length; i++) {
                        var $marker = $markers[i],
                            $position = new google.maps.LatLng($marker.lat, $marker.lng),
                            $icon = new google.maps.Marker({
                                position: $position,
                                map: $map,
                                title: $marker.name,
                                data: $marker.data
                            });

                        $markers_temp.push($icon);
                        $bounds.extend($position);

                        google.maps.event.addListener($infoWindow, 'domready', function() {
                            $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                        });

                        if (callback) {
                            callback($icon, $markers_temp, $map, $infoWindow);
                        }
                    }

                    google.maps.event.addListenerOnce($map, 'bounds_changed', function() {
                        if(this.getZoom() > $zoom) {
                            this.setZoom($zoom);
                        }
                    });

                    // $map.fitBounds($bounds);
                }

                function nl_google_map_markers_remove() {
                    for (var i = 0; i < $markers_temp.length; i++) {
                        $markers_temp[i].setMap(null);
                    }

                    $markers_temp = [];
                }
                window.nl_google_map_markers_remove_method = nl_google_map_markers_remove;
            }
            function nl_google_map_marker_click(index) {
                google.maps.event.trigger($markers_temp[index], 'click');
            }

            window.nl_google_map_markers_add_method = nl_google_map_markers_add;
            window.nl_google_map_position_method = nl_google_map_position;
            window.nl_google_map_marker_click_method = nl_google_map_marker_click;
        }
    });
};