(function($){
    fn_comp_courses_head($);
})(jQuery);

function fn_comp_courses_head($) {
    const $fn = $(".comp_course_head");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find('[data-slider]'),
                $autoplay = $fn.find('[data-slider-autoplay]').data("slider-autoplay");

            $carousel.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                autoPlay: $autoplay,
                prevNextButtons: true,
                pauseAutoPlayOnHover: true
            });
        });
    }
}