/**
 * Created by zoich on 29.08.2018.
 */

var multipleCheckboxManager;

(function($){

    function MultipleCheckboxManagerClass()
    {

        var _this = this;
        this.initialized = false;

        this.init = function()
        {
            if(!this.initialized) {
                this.initEvents();
            }

            this.checkAllItems(true);

            this.initialized = true;
        };

        this.checkAllItems = function(duringInit)
        {
            $('[data-multiple-checkbox]').each(function() {

                var wrapper = $(this);

                $(this).find('[data-checkbox-required]').each(function() {

                    var isChecked = $(this).prop('checked');

                    _this.checkMaxChecked($(this), wrapper, isChecked);
                    _this.checkMinChecked($(this), wrapper, isChecked, duringInit);
                });

            });
        };

        this.initEvents = function()
        {

            $(document).on('change','[data-checkbox-required]',function(e) {

                var wrapper = _this.getWrapper($(this));
                if(!wrapper.length) {
                    return null;
                }

                var isChecked = $(this).prop('checked');

                _this.checkMaxChecked($(this), wrapper, isChecked);
                _this.checkMinChecked($(this), wrapper, isChecked);
            });

            $('[data-checkbox-required]').bind("invalid", function(e) {

                var customText = $(this).attr('data-invalid-text');
                if(customText && customText.length) {
                    $(this).get(0).setCustomValidity(customText);
                }
            });

        };

        this.checkMinChecked = function(elem, wrapper, isChecked, duringInit)
        {

            var errorText = wrapper.closest('.col--checkbox').find('[data-checkboxes-min-error]');

            if(errorText.length && !duringInit) {
                errorText.hide();
            }

            var minChecked = wrapper.attr('data-min-required');
            minChecked = parseInt(minChecked) || 0;

            var alreadyChecked = wrapper.find('[data-checkbox-required]:checked').length;

            if(minChecked === 0) {
                minChecked = 1;
            }

            if(alreadyChecked < minChecked) {

                if(errorText.length && !duringInit) {
                    errorText.show();
                }

                wrapper.find('[data-checkbox-required]').attr('required','required');
            } else {
                wrapper.find('[data-checkbox-required]:not(:checked)').removeAttr('required')
            }

        };

        this.checkMaxChecked = function(elem, wrapper, isChecked)
        {

            var maxChecked = wrapper.attr('data-max-required');
            maxChecked = parseInt(maxChecked) || 0;

            if(maxChecked > 0) {
                var alreadyChecked = wrapper.find('[data-checkbox-required]:checked').length;
                if(alreadyChecked > maxChecked) {
                    elem.prop('checked',false);
                }
            }
        };

        this.getWrapper = function(elem)
        {
            var wrapper = elem.closest('[data-multiple-checkbox]');
            return wrapper;
        };

    }

    $(document).ready(function () {
        multipleCheckboxManager = new MultipleCheckboxManagerClass();
        multipleCheckboxManager.init();
    });

})(jQuery);


