(function($) {
    if (!$html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "" && $(this).find("input, textarea").length) {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        $(document).on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });
        $(document).on("change", ".part_ui_input.mod--file input", function (e) {
            let fileName = "";
            let label = $(this).closest(".part_ui_input").find("span");

            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption' ) || '' ).replace( '{count}', this.files.length);
            } else {
                fileName = e.target.value.split('\\').pop();
            }

            if (fileName) {
                label.text(fileName).closest(".part_ui_input").addClass("mod--selected");
            } else {
                label.text("").closest(".part_ui_input").removeClass("mod--selected");
            }

        });
        $(document).on("click", ".part_ui_input.mod--file.mod--selected", function (e) {
            e.preventDefault();
            $(this).find("input").val("").trigger("change");
        });
        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });
    }
})(jQuery);