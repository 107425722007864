(function($){
        const $fn = $(".comp_lecturers_slides");
        if ($fn.length) {
            console.log($fn.find(".elm_item_text").outerWidth());
            $.getScript(cdnjs.slick).done(function () {
                $.getStyle(cdnjs.slick_css);
                // let $slider = $fn.find("[data-slider]"),
                //     $slider_length = $slider.children().length;

                // $slider.slick({
                //     infinite: false,
                //     speed: 500,
                //     verticalSwiping: false,
                //     vertical: true,
                //     autoplay: false,
                //     arrows: false,
                //     pauseOnHover: false,
                //     dots: true,
                //     adaptiveHeight: false,
                //     responsive: [
                //         {
                //             breakpoint: 959,
                //             settings: {
                //                 swipe: false
                //             }
                //         }
                //     ]
                // });

                let $slider_partners = $fn.find("[data-slider-partners]"),
                    $slider_partners_image = $fn.find("[data-slider-partners-image]");

                $slider_partners.slick({
                    fade: true,
                    infinite: true,
                    speed: 500,
                    autoplay: true,
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"><span class="icon icon--chevron-left"></span></button>',
                    nextArrow: '<button type="button" class="slick-next"><span class="icon icon--chevron-right"></span></button>',
                    pauseOnHover: false,
                    dots: true,
                    adaptiveHeight: true,
                    autoplaySpeed: 8000,
                    asNavFor: $slider_partners_image
                });

                $slider_partners_image.slick({
                    fade: true,
                    infinite: true,
                    speed: 500,
                    arrows: false,
                    pauseOnHover: false,
                    dots: false,
                    asNavFor: $slider_partners
                });

                let $section = $fn.find(".wrp_comp_body");
                function fn_logo_fixed() {
                    let $window_top = $window.scrollTop(),
                        $elm_top = $section.offset().top - $header.height(),
                        $elm_top_table = $section.offset().top - $header.height(),
                        $elm_bottom = ($elm_top + $section.height()) - $fn.find(".elm_logo").outerHeight();

                    let $position_top;

                    if ($window.width() > 959) {
                        $position_top = $("#layout_header").height();
                    } else {
                        $position_top = "0px";
                        $elm_top = $section.offset().top;
                        $elm_top_table = $section.offset().top;
                    }

                    if ($window_top >= $elm_top) {
                        $fn.find(".elm_logo").removeClass("mod--active").addClass("mod--fixed");
                        if ($window_top >= $elm_top_table) {
                            $fn.find(".elm_logo").css({
                                "top": $position_top,
                                "width": $fn.find(".elm_item_text").parent().width(),
                                "left": $fn.find(".elm_item_text").offset().left
                            }).addClass("mod--active");
                        }
                        if ($elm_bottom <= $window_top) {
                            $fn.find(".elm_logo").css("top",$section.height()-$fn.find(".elm_logo").outerHeight()).removeClass("mod--fixed").addClass("mod--active");
                        }
                    } else {
                        $fn.find(".elm_logo").removeClass("mod--fixed mod--active");
                    }
                }

                fn_logo_fixed();
                $window.on("scroll",fn_logo_fixed);
                $window.on("resize",fn_logo_fixed);
            });
        }
})(jQuery);