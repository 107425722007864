(function($) {
    let $fn = $("#layout_header"),
        $header = $fn.find(".wrp_header_body");

    if ($fn.length) {
        $fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"></div><div class="elm_content"></div></nav>');

        var $layout_nav = $(document).find("#layout_nav"),
            $bar = $fn.find(".wrp_header_bar").clone(),
            $logo = $header.find(".elm_logo").clone(),
            $mutation = $header.find(".elm_mutation").clone(),
            $nav = $header.find(".elm_body_nav").clone();

        if ($bar.length) {
            $layout_nav.find(".elm_head").append($bar);
        }

        $layout_nav.find(".elm_head").append($logo);
        $layout_nav.find(".elm_head").append($mutation);
        $layout_nav.find(".elm_content").append($nav);

        $.getScript(cdnjs.touchswipe).done(function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.getScript(cdnjs.headroom).done(function(){
            let headroom = new Headroom($fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);