(function($){
    const $fn = $(".comp_site_about");

    if ($fn.length) {
        cssLoaded(function() {
            let $slider = $fn.find("[data-slider-info]"),
                $slider_nav = $fn.find("[data-slider-info-nav]");

            $slider.flickity({
                contain: true,
                prevNextButtons: false,
                pageDots: false,
                autoPlay: 5000,
                draggable: false,
                pauseAutoPlayOnHover: true,
                adaptiveHeight: false
            });

            $('.pause-button').on( 'click', function() {
                $carousel.flickity('pausePlayer');
            });

            $slider.on('select.flickity', function() {
                $slider_nav.removeClass('mod--active').parent().eq($slider.data('flickity').selectedIndex).children().addClass('mod--active');
            });

            $fn.on('click', '[data-slider-info-nav]', function () {
                $slider.flickity('select', $(this).parent().index(), false, true);
            });

            $(document).on({
                mouseenter: function () {
                    $slider.flickity('pausePlayer');
                },
                mouseleave: function () {
                    $slider.flickity('unpausePlayer');
                }
            },'[data-slider-info-nav]');
        });
    }
})(jQuery);