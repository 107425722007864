var showHideTimeSlotsAddListFields;
var showHideTimeSlotsAddFields;
var rollableItems;
var showHideListSlots;
var initFixedTable;

(function($){

    tippy('.hint--top', {
        allowTitleHTML: true,
        theme: 'light'
    });

    initFixedTable = function() {
        if($('.freeze-table').length) {
            console.log('init fixed header');

            $('#generatedFormEntryTable').floatThead({
                position: 'absolute',
                scrollContainer: true
            });
        }
    };

    initFixedTable();

    var $fn = $(".comp_forms_content");
    var dynamicFormSaveInterval;
    var lastTimeSlotNameSelected = null;

    if ($fn.length) {

        /*var validator = $("form.form_generated").validate({
            errorPlacement: function(error, element) {

                var elemWrapper = $(element)
                    .closest('[data-element-wrapper]');
                if(elemWrapper.length > 0) {
                    var elemLabel = elemWrapper.find("[data-error-placement]");
                    if(elemLabel.length) {

                        elemWrapper.find('.validationCustomError').remove();

                        error.addClass('validationCustomError');
                        elemLabel.append(error);
                    }
                }
            },
            errorElement: "span",
        });*/

        showHideListSlots = function(elem) {

            var topWrapper = elem.closest('.elm_courses');
            var slotsWrapper = topWrapper.find('[data-list-slot-container]');

            if(elem.hasClass('icon--chevron-down')) {
                //show
                elem.removeClass('icon--chevron-down');
                elem.addClass('icon--chevron-up');
                slotsWrapper.slideDown();
            } else {
                elem.removeClass('icon--chevron-up');
                elem.addClass('icon--chevron-down');
                slotsWrapper.slideUp();
            }

        };

        $(document).on('click','[data-show-hide-slot-list]',function() {

            var elem = $(this);
            showHideListSlots(elem);

        });

        showHideTimeSlotsAddListFields = function showHideTimeSlotsAddListFields(elem)
        {
            if(elem) {
                var wrapper = $(elem).closest('.col-time-slot-wrapper');
                var fieldsWrapper = wrapper.find('.timeSlotItem-addFields');
                var checked = $(elem).prop('checked');

                if(checked) {
                    fieldsWrapper.slideDown();
                } else {
                    fieldsWrapper.slideUp();
                }
            } else {
                $('input[data-time-slot-list-input]').each(function() {
                    showHideTimeSlotsAddListFields(this);
                });
            }
        };
        if($('[data-time-slot-entry-list-block]').length) {
            showHideTimeSlotsAddListFields();
        }

        showHideTimeSlotsAddFields = function showHideTimeSlotsAddFields(elem)
        {
            var textInputElems;

            if(elem) {
                $('.timeSlotsElementItem .timeSlotItem-addFields').not(elem).slideUp();
                textInputElems = $('.timeSlotsElementItem .timeSlotItem-addFields').not(elem).find('input[type="text"]:not(.disabled-item)');
            } else {
                $('.timeSlotsElementItem .timeSlotItem-addFields').slideUp();
                textInputElems = $('.timeSlotsElementItem .timeSlotItem-addFields').find('input[type="text"]:not(.disabled-item)');
            }

            textInputElems.each(function() {
                $(this).attr('disabled','disabled').removeAttr('required');
            });

            var checkedInput = $('input[data-time-slot-input]:checked');

            var fieldsWrapper;
            var wrapper;

            if(checkedInput.length) {
                wrapper = checkedInput.closest('div');
                fieldsWrapper = wrapper.find('.timeSlotItem-addFields').slideDown();

                textInputElems = fieldsWrapper.find('input[type="text"]:not(.disabled-item)');
                textInputElems.each(function() {
                    $(this).attr('required','required').removeAttr('disabled');

                    if(lastTimeSlotNameSelected) {
                        var itemAddFieldIndex = $(this).attr('data-add-field-item-index');
                        var oldVal = $('.timeSlotsElementItem input[name="' + lastTimeSlotNameSelected + '"]')
                            .closest('div')
                            .find('input[data-add-field-item-index="' + itemAddFieldIndex + '"]')
                            .val();
                        $(this).val(oldVal);
                    }
                });

                var textInputLabes = fieldsWrapper.find('label');

                if(textInputElems.length) {
                    textInputLabes.addClass('mod--required');
                } /*else {
                    fieldsWrapper = wrapper.find('.timeSlotItem-addFields');
                    var divInputWrappers = fieldsWrapper.find('div.part_ui_input');
                    divInputWrappers.removeClass('mod--invalid');
                    console.log('class removed');
                }*/

                lastTimeSlotNameSelected = checkedInput.attr('name');
            }

        };
        if($('[data-time-slot-entry-block]').length) {
            showHideTimeSlotsAddFields();
        }

        function saveCurrentFormData()
        {
            if(!$('form.part_form_dynamic').length) {
                return;
            }

            var formData = $('form.part_form_dynamic').serialize();
            var href = $('form.part_form_dynamic').attr('data-href');

            $.ajax({
                url: href,
                method: 'post',
                data: formData,
                dataType: 'json'
            }).done(function (payload) {

            });
        }

        function showHideFormEntryTable(elem)
        {
            var trElem = elem.closest('tr');
            var hiddenTr = trElem.next();

            if(hiddenTr.is(':visible')) {
                elem.removeClass('icon--chevron-up').addClass('icon--chevron-down');
                hiddenTr.hide();
            } else {
                elem.removeClass('icon--chevron-down').addClass('icon--chevron-up');
                hiddenTr.show();
            }
        }


        $(document).on('click', '[data-expand-form-entry]',function() {
            showHideFormEntryTable($(this));
        });

        setTimeout(function() {
            dynamicFormSaveInterval = setInterval(function() {
                saveCurrentFormData();
            },30000);
        },30000);

        $(document).on('change','input[data-time-slot-input]', function() {

            var thisChecked = $(this).prop('checked');

            $('input[data-time-slot-input]').not(this).prop('checked',false);

            if(thisChecked) {
                showHideTimeSlotsAddFields(this);
            } else {
                showHideTimeSlotsAddFields();
            }
        });

        $(document).on('change','input[data-time-slot-list-input]', function() {
            showHideTimeSlotsAddListFields(this);
        });

        $(document).on('click','[data-form-entry-delete-item]',function(e) {
            if (!confirm("Do you want to delete")){
                e.preventDefault();
                return false;
            }
        });

        $(document).on('dblclick','td[data-col-index]', function(e) {
            e.preventDefault();
            var $el = $(this);
            $el.addClass("mod--active");

            $.ajax({
                dataType: 'json',
                data: {ajax: +new Date},
                url: $(this).attr('data-ajax-link')
            }).done(function (data) {
                nl_lib_dialog.open(data.dialog, function() {
                    fn_js_actions.process(data);
                });
            });
        });

        $(document).on('dblclick','[data-custom-slot-col]', function(e) {
            e.preventDefault();
            var $el = $(this);
            $el.addClass("mod--active");

            $.ajax({
                dataType: 'json',
                data: {ajax: +new Date},
                url: $(this).attr('data-ajax-link')
            }).done(function (data) {
                nl_lib_dialog.open(data.dialog, function() {

                });
            });
        });


        $(document).on('click','[data-clear-form]',function() {
            var form = $('form.part_form_dynamic');
            if(!form.length) {
                return;
            }

            if (!confirm("Chcete z formuláře opravdu odstranit všechna data?")) {
               return;
            }

            //texty
            form.find('input[type="text"]').val('');
            form.find('input[type="email"]').val('');
            form.find('input[type="number"]').val('');
            form.find('input[type="tel"]').val('');

            form.find('textarea').val('');

            form.find('input[type="checkbox"]').prop('checked',false);
            form.find('input[type="radio"]').prop('checked',false);

            form.find('select').val('');

        });

        $('input[data-form-datepicker]').each(function() {

            var showTime = false;
            if($(this).attr('data-form-datepicker') == 'datetime') {
                showTime = true;
            }

            $(this).datepicker({
                language: 'cs',
                position: "top center",
                minDate: new Date(),
                autoClose: true,
                dateFormat: 'dd.mm.yyyy',
                timeFormat: 'hh:ii',
                timepicker: showTime,
                onSelect: function( formattedDate, date, inst) {
                }
            });
        });

        function RollableItemsClass() {

            var _this = this;

            this.init = function()
            {

                this.showHideAll();
                this.initEvents();
            };

            this.initEvents = function()
            {
                $(document).on('click','[data-rollable-toggle]',function() {
                    console.log('show hide item');
                    _this.toggleItem($(this));
                });
            };

            this.toggleItem = function(elem)
            {
                var toggleElem = elem;
                var elemId = toggleElem.attr('data-rollable-toggle');
                var toggleBlock = $('[data-rollable-block="' + elemId + '"]');

                if(!toggleBlock.length) {
                    return;
                }

                var hidden = false;
                if(toggleBlock.attr('data-rollable-hidden') == '1') {
                    hidden = true;
                }

                if(!hidden) {
                    toggleBlock.slideDown('fast');
                    toggleElem.html(toggleElem.attr('data-text-hide'));

                    toggleBlock.attr('data-rollable-hidden','1');
                } else {
                    toggleBlock.slideUp('fast');
                    toggleElem.html(toggleElem.attr('data-text-show'));

                    toggleBlock.attr('data-rollable-hidden','0');
                }
            };

            this.showHideAll = function()
            {
                $('[data-rollable-toggle]').each(function() {
                    _this.toggleItem($(this));
                });
            };

        }
        rollableItems = new RollableItemsClass();
        rollableItems.init();


    }
})(jQuery);