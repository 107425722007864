(function($){
    const $fn = $(".comp_video");
    if ($fn.length) {
        $fn.on("click", "[data-toggle-video]", function() {
            let $elm = $(this);
            let $video = $(this).find("video");

            $elm.addClass("mod--active");
            $video.attr("controls",true)[0].play();

            $video.one("play", function () {
                $(this).removeClass("mod--paused");
            });

            $video.one("ended", function() {
                $elm.removeClass("mod--active");
                $video.removeClass("mod--paused").removeAttr("controls").replaceWith($video.clone());
            });
        });

        function fn_video_scroll() {
            let elm = $fn.offset().top;
            let elm_height = $fn.height();
            let window_top = $window.scrollTop();
            let video = $fn.find("video");

            if (elm+elm_height < window_top) {
                if (!video.hasClass("mod--paused")) {
                    video.addClass("mod--paused")[0].pause();
                }
            }

        }
        if ($fn.find("[data-toggle-video]").length) {
            $window.scroll(fn_video_scroll);
        }


    }
})(jQuery);