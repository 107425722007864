(function($){
    fn_comp_site_adv_courses($);
})(jQuery);

function fn_comp_site_adv_courses($) {
    const $fn = $(".comp_site_adv_courses");

    if ($fn.length) {
        $fn.on("click","[data-table-more]",function(){
            $(this).hide().closest(".wrp_content_table").find(".elm_table [hidden]").fadeIn(300);
        });

        $fn.on("click", "[data-change-state]",function(){
            $(this).closest(".elm_switch").find(".elm_switch_link:not(.mod--active)").trigger("click")
        });

        $fn.on("click", ".elm_switch_link", function(){
            let $el = $(this),
                city = $el.data("switch-state");

            if (!$el.hasClass("mod--active")) {
                $fn.find("[data-change-state]").toggleClass("mod--active");

                $el.closest(".elm_switch").find('.elm_switch_link').removeClass("mod--active");
                $el.addClass("mod--active");

                $.ajax({
                    method: 'post',
                    data: {ajax: +new Date, do: 'changeCity', city: city},
                    dataType: "json",
                    success: function(payload) {
                        fn_ajax_handler(payload);
                    }
                });
            }
        });

        $fn.on("click", "[data-href]", function(e){
            e.preventDefault();
            window.location.href = $(this).data("href");
        });
    }
}