let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "slick": "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js",
    "slick_css": "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css",
    "masonry": "https://cdnjs.cloudflare.com/ajax/libs/masonry/4.0.0/masonry.pkgd.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.4.0/rellax.min.js",
    "dragscroll": "https://cdnjs.cloudflare.com/ajax/libs/dragscroll/0.0.8/dragscroll.min.js",
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "parallax": "https://cdnjs.cloudflare.com/ajax/libs/parallax/3.1.0/parallax.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHt8YWX5Zzs8InkVLjD9TA8aHLy-P-eaY",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.9/js/lightgallery-all.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}"
};
