(function($){
    let $layout = $("#layout_main");

    function fn_load_site($el) {
        let timeout = setTimeout(function(){
            $layout.addClass("mod--loading");
        },500);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function(payload) {
                fn_ajax_handler(payload, function(){
                    clearTimeout(timeout);
                    let $layout = $("#layout_main");

                    $layout.removeClass("mod--loading");
                    $el.closest('.part_nav').find('a').removeClass('mod--active');
                    $el.addClass('mod--active');

                    let offset;
                    if ($(window).width() > 960) {
                        offset = $("#layout_header").height()
                    } else {
                        offset = 8;
                    }

                    $('html, body').stop(true).animate({
                        scrollTop: $layout.offset().top - offset
                    }, 500);

                    $(".part_nav .part_ui_select select option").filter(`[data-url="${$el.attr('href')}"]`).attr("selected","").attr("disabled","").siblings().removeAttr("selected").removeAttr("disabled");

                    fn_comp_courses_head($);
                    fn_part_ui_timeline($);
                    fn_comp_partners($);
                    fn_comp_boxes($);
                    fn_comp_site_adv_courses($);
                });
            }
        });
    }

    $doc.on('click', '.part_nav a.ajax_site', function (e) {
        e.preventDefault();
        let $el = $(this);
        fn_load_site($el);
    });
    $doc.on('change', '.part_nav .part_ui_select select', function (e) {
        let $selected = $(this[this.selectedIndex]);
        let $el_href = $selected.data("url"),
            $el = $(".part_nav a.ajax_site").filter(`[href="${$el_href}"]`);

        $selected.attr("selected","").attr("disabled","").siblings().removeAttr("selected").removeAttr("disabled");

        fn_load_site($el);
    });
})(jQuery);