(function($){
    fn_comp_site_adv_featuring($);
})(jQuery);

function fn_comp_site_adv_featuring($) {
    const $fn = $(".comp_site_adv_featuring");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find('[data-slider]');

            $carousel.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                autoPlay: false,
                prevNextButtons: true,
                pauseAutoPlayOnHover: true
            });

            let $carousel_img = $carousel.find('.elm_item .elm_item_image img'),
                docStyle = document.documentElement.style,
                transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

            let flkty = $carousel.data('flickity');

            $carousel.on('scroll.flickity', function () {
                flkty.slides.forEach(function (slide, i) {
                    let img = $carousel_img[i],
                        x = (slide.target + flkty.x) * -1 / 3;

                    img.style[transformProp] = 'translateX(' + x + 'px)';
                });
            });
        });
    }
}