(function($){
    fn_comp_site_adv_interviews($);
})(jQuery);

function fn_comp_site_adv_interviews($) {
    const $fn = $(".comp_site_adv_interviews");

    if ($fn.length) {
        $fn.on("click","[data-click=\"expand\"]",function () {
            let el = $(this);

            if(el.data("target") === "all") {
                el.toggleClass("mod--active");
                $fn.find("[data-ref]").toggleClass("mod--active",el.hasClass("mod--active"));
            }
            else {
                $fn.find("[data-ref=\"" + el.data("target") + "\"]").toggleClass("mod--active");
            }
        })
    }
}