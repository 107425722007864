(function($){
    const $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find('[data-visual-slider]'),
                $caption = $fn.find('[data-visual-caption]'),
                $autoplay = $fn.find('[data-visual-autoplay]').data("visual-autoplay");

            if ($caption.children().length > 1) {
                $caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false
                });
            } else {
                $caption.addClass("flickity-disabled");
            }

            $.getScript(cdnjs.parallax).done(function(){
                $fn.find("[data-parallax-scene]").each(function(){
                    let parallax = new Parallax($(this)[0],{
                        scalarX: 16,
                        scalarY: 0
                    });
                });
            });

            if ($carousel.children().length > 1) {
                $carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    sync: '[data-visual-caption]',
                    autoPlay: $autoplay,
                    prevNextButtons: true,
                    pauseAutoPlayOnHover: true
                });

                let $carousel_img = $carousel.find('.elm_item .elm_item_image'),
                    docStyle = document.documentElement.style,
                    transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                let flkty = $carousel.data('flickity');

                $carousel.on('scroll.flickity', function () {
                    flkty.slides.forEach(function (slide, i) {
                        let img = $carousel_img[i],
                            x = (slide.target + flkty.x) * -1 / 3;

                        img.style[transformProp] = 'translateX(' + x + 'px)';
                    });
                });
            }
        });
    }
})(jQuery);